<template lang="pug">
section.news-list
  h2.section-title-2 {{ i18n.t("News.NewsTitle") }}

  ErrorAlert(
    v-if="error"
    :reporting="{ message: error.message, extra: error }"
    :stack="error.stack"
    message="Failed to load the news."
    @retry="query.refetch"
  )

  VSkeletonLoader(
    v-else-if="query.isPending.value"
    type="article"
  )

  NewsCard.mb-2(
    v-for="doc in newsDocs"
    :key="doc.its_nid"
    :nid="doc.its_nid"
    :title="doc.ss_title"
    :body-html="doc.ss_body"
    :changed="new Date(doc.ds_changed)"
    :created="new Date(doc.ds_created)"
    :sticky="doc.bs_sticky"
  )
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useQuery } from "@tanstack/vue-query";
import { injectRequired } from "~/injectRequired";
import { LoggerKey, SolrClientKey } from "~/injectionKeys";
import {
  logSsrErrorToSentry,
  makeResultAsyncSerializable,
} from "~/errors/util";

const i18n = useI18n();
const locale = i18n.locale;
const solrClient = injectRequired(SolrClientKey);
const logger = injectRequired(LoggerKey);

const props = withDefaults(
  defineProps<{
    /**
     * The maximum number of news articles to show.
     */
    max?: number;
  }>(),
  {
    max: 10,
  },
);

const query = useQuery({
  queryKey: ["latest-news", locale, computed(() => props.max)],
  queryFn: () =>
    makeResultAsyncSerializable(
      () =>
        solrClient.getLatestAppNews(
          [
            "ss_title",
            "ss_body",
            "ds_created",
            "ds_changed",
            "bs_sticky",
            "its_nid",
          ],
          true,
          [locale.value],
          0,
          props.max,
        ),
      (e, s) => logSsrErrorToSentry(logger, e, s),
    ),
});

const error = computed(
  () => query.error.value ?? query.data.value?.error ?? null,
);

const newsDocs = computed(() => query.data.value?.value?.docs ?? []);

onServerPrefetch(async () => {
  await query.suspense();
});
</script>
