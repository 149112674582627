<template lang="pug">
VCard(tag="article")
  VCardTitle.py-4.text-h2(tag="h3")
    VIcon(v-if="sticky") {{ mdiPin }}
    NuxtLink.a(:to="localePath(getNewsLocation(nid))") {{ title }}

  VCardText.text-body-2(v-html="bodyHtml")
</template>

<script setup lang="ts">
import { computed } from "vue";
import { mdiPin } from "@mdi/js";
import { getNewsLocation } from "~/routing/locations";

const localePath = useLocalePath();

interface Props {
  title: string;

  bodyHtml: string;

  nid: number;

  sticky: boolean;

  created: Date;

  changed: Date;
}

const props = defineProps<Props>();

const articleJsonLd = computed(() => {
  return {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: props.title,
    datePublished: props.created,
    dateModified: props.changed,
  };
});

useHead({
  script: [
    {
      type: "application/ld+json",
      innerHTML: JSON.stringify(articleJsonLd.value),
    },
  ],
});
</script>
