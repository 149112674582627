<template lang="pug">
div.the-footer.mt-8
  VDivider
  VFooter.the-footer__links.surface.pt-6.pb-6.pl-4.pr-4
    VContainer.d-flex.align-center
      div.text-h3.d-flex.align-center
        VvLogo.text-h2(
          logo-id="vv-logo-footer"
          :text="true"
        )
        | Vox Viva
      // TODO: Create an about page?
      //VDivider.mx-2.ml-6(vertical)
      //VBtn(to="about" plain)
      //  | About
      VBtn.ml-auto(
        size="small"
        :to="localePath(getBibleSearchLocation())"
        aria-label="Bible search"
        variant="flat"
      )
        VIcon.mr-1(size="small") {{ mdiBookSearchOutline }}
        | {{ i18n.t("TheFooter.search") }}
  VDivider
  VFooter.pt-4.pb-4.pl-2.pr-2
    VContainer.d-flex.align-center
      div.text-caption
        | {{ i18n.t("TheFooter.projectBy") }}
        br
        a.a(href="https://www.refstudycentre.com") Reformational Study Centre

      VSpacer

      div
        VBtn.mx-2(
          size="small"
          variant="text"
          :icon="true"
          href="https://lamad.app"
          :title="i18n.t('TheFooter.lamad')"
          :aria-label="i18n.t('TheFooter.lamad')"
        )
          VImg(
            width="24px"
            height="24px"
            src="/img/logo_lamad.png"
            :alt="i18n.t('TheFooter.lamadLogoAlt')"
          )
        VMenu
          template(#activator="activatorProps")
            VBtn.mx-2(
              size="small"
              variant="text"
              :icon="true"
              v-bind="activatorProps['props']"
              :title="i18n.t('TheFooter.Libraries')"
              :aria-label="i18n.t('TheFooter.Libraries')"
            )
              VImg(
                width="24px"
                height="24px"
                src="/img/logo_cl.png"
                :alt="i18n.t('TheFooter.LibrariesLogoAlt')"
              )
          VList
            VListItem(
              v-for="{ href, name, lang } in cl"
              :key="name"
              :href="href"
            )
              VListItemTitle {{ name }}
              template(#prepend)
                VBadge(
                  :content="lang"
                  :inline="true"
                )
</template>

<script setup lang="ts">
/**
 * The info footer for the home page.
 */
import { useI18n } from "vue-i18n";

import { mdiBookSearchOutline } from "@mdi/js";
import { getBibleSearchLocation } from "~/routing/locations";

const i18n = useI18n();
const localePath = useLocalePath();

const cl = [
  {
    href: "https://www.christianstudylibrary.org",
    name: "Christian Library",
    lang: "en",
  },
  {
    href: "https://www.christelikemedia.org",
    name: "Christelike Media",
    lang: "af",
  },
  {
    href: "https://www.ressourceschretiennes.com",
    name: "Ressources chrétiennes",
    lang: "fr",
  },
];
</script>
