<template lang="pug">
div
  TheHomeBanner

  VContainer.pt-5.pb-5
    h2.section-title-2.mt-5.mb-0
      | {{ i18n.t("Home.exploreComments") }}
  div.pb-10
    IndexListScroller(:local-tid="localTid")

  div.surface
    VContainer.pt-8.pb-8
      div.text-center.text-body-1
        i18n-t.mt-2(
          keypath="Home.about1"
          tag="p"
          scope="global"
        )
          br
        i18n-t(
          keypath="Home.about2"
          tag="p"
          scope="global"
        )
          br
  VContainer
    VRow
      VCol
        NewsList
  TheFooter
</template>

<script setup lang="ts">
import { computed } from "vue";
import { localesByCode } from "~/i18n/locales";
import { never } from "~/never";

const runtimeConfig = useRuntimeConfig();
const i18n = useI18n();
const localeConfig = computed(
  () => localesByCode.get(i18n.locale.value) ?? never("Locale not found."),
);
const localTid = computed<string>(() => localeConfig.value.defaultTid);

useHead({
  title: runtimeConfig.public.site.name,
});
</script>
